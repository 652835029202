import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';
import Spinner from '@components/Spinner/Spinner';
import Newsletter from '@components/Newsletter/Newsletter';
import ButtonOutline from '@components/ButtonOutline/ButtonOutline';

import Colors from '@utils/colors';
import Utils from '@utils/';
import Breakpoints from '@utils/breakpoints';

import Img from '@static/img/tina.jpg';

import PageHeader from '@components/PageHeader/PageHeader';
import PageContent from '@layout/PageContent';

const Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  modalStyles: {
    top: '20px',
    left: '20px',
    right: '20px',
    bottom: '20px',
    zIndex: 500,
    background: Colors.alternateBg,
    border: 'none',
    padding: '0',
    position: 'absolute',
    overflow: 'auto',
    [`@media (max-width: ${Breakpoints.s})`]: {
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
    },
  },
  close: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    zIndex: '150',
    color: Colors.white + ' !important',
    textDecoration: 'none !important',
    backgroundColor: Colors.accent,
    borderRadius: '4px',
    padding: '10px 15px',
    fontSize: '14px',
    textTransform: 'uppercase',
    display: 'inline-block',
    fontWeight: 'bold',
    transition: Utils.transition('background', '.2s'),
    letterSpacing: 1,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: Colors.accentDark,
    },
    [`@media (max-width: ${Breakpoints.s})`]: {
      top: '10px',
      fontSize: '14px',
      padding: '5px 10px',
    },
  },
  iframe: {
    zIndex: 99999,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '20px',
    marginTop: '40px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      display: 'block',
    },
  },
  left: {
    width: '40%',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      marginBottom: '40px',
    },
  },
  right: {
    width: '60%',
    paddingLeft: '40px',
    [`@media (max-width: ${Breakpoints.s})`]: {
      width: '100%',
      paddingLeft: '0',
      marginBottom: '40px',
    },
  },
};

const useStyles = createUseStyles(Styles);

const ConsulenzaGratuita = ({ location, ...props }) => {
  const classes = useStyles(props);

  const title = 'Sei pronta per fare pace con il tuo corpo e il cibo?';
  const subtitle = '';

  const handleBook = () => {
    const emailTo = 't.helboe@gmail.com';
    window.open('mailto:' + emailTo, '_self');
  };

  // useEffect(() => {
  //   if (window.Calendly) {
  //     setTimeout(() => {
  //       window.Calendly.initInlineWidget({
  //         url: `https://calendly.com/liveflow/consulenza-gratuita?primary_color=ff976c`,
  //         parentElement: document.getElementById('calendly-inline-widget'),
  //         prefill: {},
  //         utm: {},
  //       });
  //     }, 200);
  //   }
  // }, []);

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <PageContainer>
        <PageContent>
          <PageHeader title={title} subtitle={subtitle} nomargin left />
          <div className={classes.row}>
            <div className={classes.left}>
              <img src={Img} alt="Lezione gratuita" />
            </div>
            <div className={classes.right}>
              Se sei pronta a liberarti finalmente dalla mentalità della dieta e
              trovare la pace con il corpo e il cibo ti invito a prenotare qui
              una sessione gratuita.
              <br />
              In questa mini sessione di 30 minuti avremo l'occasione di:
              <ul>
                <li>Conoscerci</li>
                <li>Scoprire che cosa ti ostacola</li>
                <li>Capire quali sono alcune delle tue sfide</li>
              </ul>
              In piu'
              <ul>
                <li>io ti posso offrire idee e consigli</li>
                <li>e tu mi puoi fare le domande</li>
              </ul>
              Concluso la sessione avrai capito se questo approccio è giusto per
              te e se io sono una persona con la quale avresti voglia di
              lavorare.
              <br />
              <br />
              <ButtonOutline
                to="#"
                label="Richiedi consulenza gratuita"
                onClick={e => {
                  e.preventDefault();
                  handleBook();
                }}
              />
            </div>
          </div>
        </PageContent>

        {/* <div className="relative">
          <div className="absolute_spinner">
            <Spinner loading={true} />
          </div>
          <div
            id="calendly-inline-widget"
            className="calendly-inline-widget absolute_calendly"
            data-url="https://calendly.com/liveflow/consulenza-gratuita?primary_color=ff976c"
            style={{ minWidth: '320px', height: '860px' }}
          ></div>
        </div> */}

        <Newsletter />
      </PageContainer>
    </Layout>
  );
};

export default ConsulenzaGratuita;
